<template>
    <v-chart class="chart" :option="option" />
  </template>
  
  <script>
  import { use } from "echarts/core";
  import { CanvasRenderer } from "echarts/renderers";
  import { LineChart, BarChart } from "echarts/charts";
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent,
  } from "echarts/components";
  import VChart, { THEME_KEY } from "vue-echarts";
  import { max } from "zrender/lib/core/vector";
  
  use([
    CanvasRenderer,
    LineChart,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent,
  ]);
  
  export default {
    props: [],
    components: {
      VChart,
    },
    provide: {
      // [THEME_KEY]: "dark"
    },
    data() {
      return {
        option : {
          xAxis: {
            type: 'category',
            data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: [
                120,
                120,
                80,
                70,
                110,
                130,
                110,
                220,
                30,
                50,
                {
                  value: 300,
                  itemStyle: {
                    color: '#a90000'
                  }
                },
                200,
                160

              ],
              type: 'bar'
            }
          ]
        }
      };
    },
  };
  </script>
  
  <style scoped>
  .chart {
    height: 300px;
    width: 80%
  }
  </style>
  
  